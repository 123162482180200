.App {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.Stats {
  color: white;
  background-color: #3366cc;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #3366cc;
  padding: 10px;
}


.Estimates {
  border: 1px solid #888888;
  padding: 10px;
}

.Estimates .List {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.Estimates .Heading {
  margin: 5px 0;
}

.Estimates input {
  border: 1px solid #888;
  margin: 10px 10px 0 0;
  width: 60px;
  font-size: 16px;
  line-height: 16px;
  padding: 5px;
}

.Estimates span {
  width: 40px;

  padding: 5px;
  margin: 10px 10px 0 0;
  border: 1px solid #3366cc;
  background-color: #3366cc;
  color: white;
  text-align: center;
  cursor: pointer;
}

a.btn {
  display: inline-block;
  padding: 5px 10px;
  margin: 10px 10px 0 0;
  border: 1px solid #3366cc;
  color: #3366cc;
  cursor: pointer;
}

em {
  font-weight: bold;
}